@import "Styles/_mixins";

.top-sidebar {
    border-radius: 20px;
    border: 1px solid #E9E9E9;
    backdrop-filter: blur(6px);

    &__title {
        .br(20px);

        background: var(--black-color);
        box-shadow: -3px 1px 6px 0px rgba(0, 0, 0, 0.10), 
                    -11px 4px 11px 0px rgba(0, 0, 0, 0.09), 
                    -24px 9px 15px 0px rgba(0, 0, 0, 0.05), 
                    -42px 16px 18px 0px rgba(0, 0, 0, 0.01), 
                    -66px 25px 20px 0px rgba(0, 0, 0, 0.00);
        backdrop-filter: blur(2px);
        color: var(--white-color);
        font-size: 16px;
        font-weight: 400;
        line-height: 1.1875;
        margin: 0 0 30px;
        padding: 5px 20px;
        text-align: center;

        @media (@sm-up and @xl) {

            width: 50%;
        }

        b {
            font-weight: 500;
        }
    }
    &__list {
        padding: 0 25px 30px;

        @media (@md-up and @xl) {
            align-items: stretch;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-item {
            border-bottom: 1px solid var(--line-color);
            box-sizing: border-box;
            display: block;
            margin-bottom: 20px;
            padding: 0 23px 20px;

            @media (@md-up and @xl) {
                position: relative;
                width: 45%;

                &:nth-child(2n) {
                    &:before {
                        background: var(--line-color);
                        bottom: 20px;
                        content: '';
                        left: -10%;
                        position: absolute;
                        top: 20px;
                        width: 1px;
                    }
                }
            }

            &:last-child {
                @media (@md or @xl-up) {
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
}
.top-sidebar-item {
    &__img-wrap {
        .br(15px);

        background: #FFF;
        border: 1px solid var(--line-color);
        overflow: hidden;
        padding-top: 58%;
        position: relative;
        margin: 0 0 16px;
    }
    &__img {
        display: block;
        left: 50%;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
    &__flag {
        display: block;
        left: 8px;
        max-height: 29px;
        max-width: 58px;
        object-fit: contain;
        object-position: left top;
        position: absolute;
        top: 8px;
        z-index: 2;
    }
    &__label {
        align-items: center;
        background: var(--black-color);
        color: var(--white-color);
        display: flex;
        font-size: 12px;
        font-weight: 500;
        justify-content: center;
        padding: 7px 8px 6px;
        position: absolute;
        right: 8px;
        top: 7px;

        .icon {
            .size(15px);

            display: block;
            margin-right: 5px;
        }
    }
    &__name {
        font-family: var(--font-title);
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 10px;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: var(--primary-color);
                text-decoration: none;
            }
        }
    }
    &__desc {
        font-size: 14px;
    }
    &__btn-wrap {
        margin-top: 20px;
    }
    &__btn {
        width: 1100px;
    }
}