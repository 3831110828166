@import "Styles/_mixins";

.advantages-list {
    margin: 0 0 40px;

    &__item {
        align-items: center;
        border-bottom: 1px solid var(--text-color);
        display: flex;
        justify-content: flex-start;
        margin: 0 0 20px;
        padding-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        &-icon {
            .br(5px);
            .size(26px);

            align-items: center;
            background: var(--primary-color);
            display: flex;
            justify-content: center;
            margin-right: 12px;
            min-width: 26px;

            .icon {
                .size(22px);

                display: block;
            }
        }
        &-name {            
            font-size: 20px;
        }
    }
}