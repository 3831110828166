@import "Styles/_mixins";

.breadcrumbs {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin: 0 0 46px;

    @media @xl {
        margin-bottom: 32px;
    }
    @media @md {
        margin-bottom: 26px;
    }
    @media @sm {
        margin-bottom: 16px;
    }

    &__item {
        display: inline-block;
        margin-right: 0.3em;
        vertical-align: baseline;

        &:last-child {
            margin-right: 0;

            &:after {
                display: none;
            }
        }

        &:after {
            content: '-';
            display: inline;
            margin-left: .5em;
        }
    }
    &__link {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: var(--primary-color);
            text-decoration: none;
        }
    }
    
}