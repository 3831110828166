@import "Styles/_mixins";

.top-section {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 80px;
    width: 100%;

    @media @lg {
        margin: 0 0 60px;
    }
    @media @sm {
        margin: 0 0 40px;
    }

    &__cont {
        align-items: stretch;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-around;
    }
    &__main {
        margin: 76px 0 85px;

        @media @xl {
            margin: 79px 0 68px;
        }
        @media @lg {
            margin: 54px 0 46px;
        }
        @media @md {
            margin: 44px 0 38px;
        }
    }
    &__addition {
        margin: 0;
    }
    &__title {
        font-size: clamp(24px, 3.5cqi, 55px);
        font-weight: bold;
        line-height: 1.16;
        margin: 0;
        text-transform: uppercase;
        text-wrap: balance;

        @media @md {
            margin: 0 0 40px;
            text-align: center;
        }
    }
    &__bottom {
        background: var(--white-color);
        box-shadow: -2px 2px 6px 0px rgba(135, 135, 135, 0.25), 
                    -8px 7px 10px 0px rgba(135, 135, 135, 0.21), 
                    -17px 15px 14px 0px rgba(135, 135, 135, 0.13), 
                    -31px 27px 16px 0px rgba(135, 135, 135, 0.04);
        margin-top: 110px;
        overflow: hidden;
        padding: 4px 0;
        width: 100%;

        @media @xl {
            margin-top: 80px;
        }
        @media @lg {
            margin-top: 70px;
        }
        @media @md {
            margin-top: 60px;
        }
    }
}

.top-section-addition {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    gap: var(--gutter-x);

    @media @md {
        flex-direction: column;
    }

    &__item {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: flex-start;
        
        &:hover {
            .top-section-addition__item-desc {
                opacity: 1;
            }
        }

        &-cont {
            flex: 1;
            position: relative;
        }
        &-icon {
            .br(50px);
            .size(30px);

            align-items: center;
            border: 1px solid var(--black-color);
            box-sizing: border-box;
            color: var(--black-color);
            display: flex;
            justify-content: center;
            margin-right: 12px;
            min-width: 30px;

            @media @xl {
                .size(20px);

                margin-right: 6px;
                min-width: 20px;
            }

            .icon {
                .size(4px, 18px);

                display: block;
                max-height: 100%;
                max-width: 100%;

                @media @xl {
                    .size(3px, 14px);
                }
            }
        }
        &-name {
            font-family: var(--font-title);
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;

            @media @xl {
                font-size: 16px;
            }
        }
        &-desc {
            .br;
            .trs;

            background: var(--black-color);
            box-sizing: border-box;
            color: var(--white-color);
            font-size: 18px;
            left: 0;
            opacity: 0;
            padding: 7px 25px 6px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            text-wrap: balance;
            width: 100%;

            @media @xl {
                font-size: 14px;
                padding: 5px 12px;
            }
        }
    }
}