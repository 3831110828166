// GRID
@import (inline) "~bootstrap/dist/css/bootstrap-grid.css";
@import (inline) "~bootstrap/dist/css/bootstrap-utilities.css";

/* WRAPPER */
.page-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-height: 99.9vh;
    min-width: 360px;
    padding: 0;
    width: 100%;

    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        --bs-gutter-x: var(--gutter-x);        
    }
}

/* CONTENT */
.page-container {
    display: block;
    flex: 1;
    margin-bottom: 70px;
    position: relative;

    @media @lg {
        margin-bottom: 60px;
    }
    @media @sm {
        margin-bottom: 60px;
    }
}

.page-content {
    display: block;
}
.container {
    box-sizing: border-box;

    @media @xxl {
        max-width: 100%;
    }
    @media @xl {
        padding-left: 52px;
        padding-right: 52px;
    }
    @media @md {
        padding-left: 20px;
        padding-right: 20px;
    }
}
