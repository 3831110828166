/* PLUGINS RESTYLE */

//Fancybox
@import (inline) "~@fancyapps/ui/dist/fancybox/fancybox.css";

// SimpleBar
@import (inline) "~simplebar/dist/simplebar.css";

.simplebar-track {

    &.simplebar-vertical {
        width: 2px;
    }
    .simplebar-scrollbar:before {
        background: var(--white-color);
        bottom: 0;
        left: 0;
        opacity: 1;
        right: 0;
        top: 0;
    }
}