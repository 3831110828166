@import "Styles/_mixins";

.chapter-list {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 30px 30px;

    &__item {
        display: block;
        width: calc((100% - 30px) / 2);

        @media @md {
            width: 100%;
        }

        &_full {
            width: 100%;
        }
    }
}

.chapter-item {
    &_black {
        .chapter-item__cont {
            background: var(--black-color);
            color: var(--white-color);
        }
        .chapter-item__img-wrap {
            background: var(--white-color);
        }
    }
    &_grey {
        .chapter-item__cont {
            background: var(--light-grey);
            color: var(--white-color);
        }
        .chapter-item__img-wrap {
            background: var(--white-color);
        }
    }
    &__cont {
        .br(20px, 23px, 20px, 20px);

        background: var(--white-color);
        box-shadow: 0px 0px 0px 0px rgba(135, 135, 135, 0.25), -2px 2px 6px 0px rgba(135, 135, 135, 0.25), -8px 7px 10px 0px rgba(135, 135, 135, 0.21), -17px 15px 14px 0px rgba(135, 135, 135, 0.13), -31px 27px 16px 0px rgba(135, 135, 135, 0.04), -48px 42px 18px 0px rgba(135, 135, 135, 0.00);
        // overflow: hidden;
    }
    &__head {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 0 30px;
        width: 100%;

        @media @md {
            margin-bottom: 20px;
        }
    }
    &__name {
        font-family: var(--font-title);
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        padding: 10px 10px 10px 25px;

        @media @xl {
            font-size: 20px;
        }
        @media @md {
            padding: 10px 10px 10px 20px;
        }

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: var(--primary-color);
                text-decoration: none;
            }
        }
    }
    &__img-wrap {
        .br(20px);
        .size(100px);

        align-items: center;
        background: var(--black-color);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        margin-left: auto;
        min-width: 100px;
        padding: 6px;
    }
    &__img {
        .size(100%);

        display: block;
        object-fit: contain;
        object-position: center;
    }
    &__desc {
        font-size: 18px;
        font-weight: 400;
        padding: 0 25px 25px;

        @media @md {
            font-size: 16px;
            padding: 0 20px 20px;
        }
        @media @sm {
            font-size: 14px;
        }
    }
    &__btn-wrap {
        margin-top: 12px;
    }
    &__btn {
        align-items: center;
        display: flex;
        font-size: 20px;
        justify-content: center;
        padding: 15px 32px;

        .icon {
            .size(17px);

            display: block;
            margin-left: auto;
        }
    }
}