@import "Styles/_mixins";

.footer-menu {
    align-items: start;
    display: block;
    flex: 1;

    &__line {
        align-items: center;
        border-bottom: 1px solid currentColor;
        display: flex;
        font-size: 0.875rem;
        justify-content: space-between;
        padding: 0.62rem 0;

        &:first-child {
            font-size: 1rem;
        }
        &:last-child {
            border: none;
        }
    }
    &__item {
        display: block;
    }
    &__link {
        color: currentColor;
        text-decoration: none;

        &:hover {
            color: var(--primary-color);
            text-decoration: none;
        }
    }
}