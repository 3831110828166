/* TAGS */
::selection {
	background-color: var(--primary-color);
	color: var(--text-color);
}

body {
	-webkit-tap-highlight-color: transparent;

	background: var(--body-bg, #fff);
	color: var(--text-color, #333);
	font-family: var(--font-default, sans-serif);
	font-size: var(--font-size, 16px);
	line-height: var(--line-height, 1.3);

	@media @lg {
		font-size: 1rem;
	}
}

a {
	color: var(--text-color);
	cursor: pointer;
	text-decoration: none;
	transition: all var(--transition-speed);
	-webkit-tap-highlight-color: transparent;

	&:hover {
		color: var(--text-color);
		text-decoration: none;
	}

	&:active,
	&:hover,
	&:focus {
		-webkit-tap-highlight-color: transparent;
	}
}

h1,
.h1 {
	font-family: var(--font-title);
	font-size: 32px;
	font-weight: 700;
	line-height: 1.18;
	margin: 0 0 20px;

	@media @lg {
		font-size: 20px;
	}
}
h2,
.h2 {
	font-family: var(--font-title);
	font-size: 28px;
	font-weight: 500;
	line-height: 1.18;
	margin: 0 0 20px;

	@media @lg {
		font-size: 18px;
	}
}
h3,
.h3 {
	font-family: var(--font-title);
	font-size: 20px;
	font-weight: 700;
	line-height: 1.18;
	margin: 0 0 20px;

	@media @lg {
		font-size: 16px;
	}
}
h4,
.h4 {
	font-family: var(--font-title);
	font-size: 20px;
	font-weight: 500;
	line-height: 1.18;
	margin: 0 0 20px;

	@media @lg {
		font-size: 16px;
	}
}
h5,
.h5 {
	font-family: var(--font-title);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.18;
	margin: 0 0 20px;

	@media @lg {
		font-size: 16px;
	}
}
.main-title {
	.h1;

	color: var(--text-color);
	margin: 25px 0 1.5em;
	padding: 0 0 0.25em;
	position: relative;
	text-align: center;

	@media @xl {
		margin-top: 48px;
	}
	@media @sm {
		margin-top: 20px;
	}

	&_left {
		text-align: left;

		@media @sm {
			text-align: center;
		}
	}
}
p {
	margin: 1em 0;
}
iframe,
img,
embed,
table {
	max-width: 100%;
}
ul, 
ol {
	padding-inline-start: 30px;

	li {
		margin-bottom: 0.6em;
	}
}
hr {
	border-color: var(--primary-color);
	display: block;
	margin-inline-start: 0;
	max-width: 100%;
}

.section-page {
	margin: 40px 0 0;

	&__head {
		margin-bottom: 20px;
		padding-bottom: 21px;
		position: relative;

		&:after {
			.size(350px, 1px);

			background: currentColor;
			bottom: 0;
			content: '';
			display: block;
			left: 0;
			max-width: 100%;
			position: absolute;
		}
	}
    &__title {
        margin: 0;
    }
    &__desc {
        font-size: var(--font-size);
        margin: 0 auto;
    }
}

.br {
	.br();
}
.bxsh {
	box-shadow: 0px 0px 0px 0px rgba(135, 135, 135, 0.25), -2px 2px 6px 0px rgba(135, 135, 135, 0.25), -8px 7px 10px 0px rgba(135, 135, 135, 0.21), -17px 15px 14px 0px rgba(135, 135, 135, 0.13), -31px 27px 16px 0px rgba(135, 135, 135, 0.04), -48px 42px 18px 0px rgba(135, 135, 135, 0.00);
}

.flag-detail {
	align-items: center;
	display: flex;
	margin: 20px 0;
	justify-content: flex-start;
	padding: 11px 0;
	position: relative;

	&::before {
		.size(255px, 1px);
		
		background: var(--black-color);
		content: '';
		display: block;
		left: 0;
		max-width: 100%;
		position: absolute;
		top: 0;
		@media @md {
			width: 100%;
		}
	}
	&::after {
		.size(255px, 1px);

		background: var(--black-color);
		content: '';
		bottom: 0;
		display: block;
		left: 0;
		max-width: 100%;
		position: absolute;

		@media @md {
			width: 100%;
		}
	}
	&__img {
		.br(10px);
		.size(58px, auto);

		display: block;
		margin-right: 36px;
		object-fit: cover;
		object-position: center;

		@media @xl {
			margin-right: 12px;
		}
	}
	&__name {
		color: #000;
		font-family: var(--font-title);
		font-size: 24px;
		font-style: normal;
		font-weight: 400;
	}
}