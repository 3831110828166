@import "Styles/_mixins";

.systems-list {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 30px;

    &__item {
        display: block;
        width: calc((100% - 30px) / 2);

        @media @md {
            width: 100%;
        }

        &_full {
            width: 100%;
        }
    }
}

.systems-item {
    
    &__cont {
        .br(20px);

        align-items: flex-start;
        background: var(--white-color);
        box-shadow: 0px 0px 0px 0px rgba(135, 135, 135, 0.25), -2px 2px 6px 0px rgba(135, 135, 135, 0.25), -8px 7px 10px 0px rgba(135, 135, 135, 0.21), -17px 15px 14px 0px rgba(135, 135, 135, 0.13), -31px 27px 16px 0px rgba(135, 135, 135, 0.04), -48px 42px 18px 0px rgba(135, 135, 135, 0.00);
        display: flex;
        justify-content: flex-start;
        padding: 20px;
        position: relative;

        @media @xl {
            display: block;
        }

        &-enternal {
            display: block;
            flex: 1;
        }
    }
    &__name {
        font-family: var(--font-title);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        margin: 0 0 20px;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: var(--primary-color);
                text-decoration: none;
            }
        }
    }
    &__img-wrap {
        .br(20px);

        background: var(--white-color);
        box-sizing: border-box;
        display: block;
        margin: 0 20px 0 0;
        overflow: hidden;
        width: 40%;

        @media @xl {
            margin: 0 0 25px;
            width: 100%;
        }
    }
    &__img {
        display: block;
        max-height: 280px;
        object-fit: contain;
        object-position: center;
        width: 100%;

        @media @xl {
            max-height: none;
        }
    }
    &__flag {
        .br(10px);

        display: block;
        left: 0;
        max-height: 30px;
        max-width: 58px;
        object-fit: contain;
        object-position: left top;
        position: absolute;
        top: 0;
        z-index: 2;
    }

    &__desc {
        font-size: 16px;
        font-weight: 400;
    }
    &__btn-wrap {
        margin-top: 20px;
    }
    &__btn {
        .br(10px);

    }
}

.btn-more-wrap {
	align-items: center;
	display: flex;
	justify-content: center;
	margin-top: 50px;
	width: 100%;

	&__btn {
		font-size: 20px;
		margin: auto;
		max-width: 350px;
		padding: 15px 20px 14px;
		text-transform: uppercase;
	}
}