/* FONTS */
// Roboto & OpenSans
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Roboto:wght@400;500;700&display=swap');

@font-face {
    font-family: 'San Francisco Pro Display';
    font-display: swap;
  	src: url('../fonts/San_Francisco_Pro_Display/sf-pro-display-regular.eot');
  	src: url('../fonts/San_Francisco_Pro_Display/sf-pro-display-regular.eot?#iefix') format('embedded-opentype'),
  		url('../fonts/San_Francisco_Pro_Display/sf-pro-display-regular.woff2') format('woff2'),
  		url('../fonts/San_Francisco_Pro_Display/sf-pro-display-regular.woff') format('woff'),
  		url('../fonts/San_Francisco_Pro_Display/sf-pro-display-regular.ttf') format('truetype');
    font-weight: normal;
}