/* FORMS */
/* placeholder */
::placeholder {
	color: fade(#000000, 50%);
}
/* wrappers */
.form-row {
	margin: 0 auto 40px;
	position: relative;

	@media @md {
		margin-bottom: 32px;
	}
	@media @sm {
		margin-bottom: 28px;
	}
}
.btn-row {
	margin: 20px auto 0;

	@media @sm {
		margin-top: 16px;	
	}
}

/* labels */
label {
	cursor: pointer;

	&.option {
		.trs;

		color: var(--text-color);
		display: block;
		font-family: var(--font-title);
		font-size: 16px;
		font-weight: 400;
		text-transform: uppercase;
		top: 0;
		transform: translateY(0);
		width: 100%;
	}
}


/* textfields */
.textfield {
	background: var(--body-bg);
	border: 0 solid var(--primary-color);
	border-width: 0 0 1px;
	border-radius: 0;
	box-sizing: border-box;
	color: var(--text-color);
	display: block;
	font-family: var(--font-title);
	font-size: 16px;
	font-weight: 400;
	line-height: 110.69%;
	outline: none;
	padding: 4px 0;
	width: 100%;
}
textarea {
	resize: none;
}

/* buttons */
input[type='button'],
input[type='submit'],
button {
		cursor: pointer;
}
.btn {
	.br(10rem);
	
	background: var(--black-color);
	border: 1px solid var(--black-color);
	box-shadow: 0px 0px 0px 0px rgba(135, 135, 135, 0.25), -2px 2px 6px 0px rgba(135, 135, 135, 0.25), -8px 7px 10px 0px rgba(135, 135, 135, 0.21);
	box-sizing: border-box;
	color: var(--white-color);
	cursor: pointer;
	display: block;
	font-family: var(--font-title);
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.188rem;
	max-width: 100%;
	outline: none;
	padding: 10px 18px 9px;
	text-align: center;
	text-decoration: none;
	width: 100%;

	&:hover {
		background-color: var(--black-color);
		border-color: var(--black-color);
		box-shadow: 0px 0px 0px 0px rgba(135, 135, 135, 0.25), -2px 2px 6px 0px rgba(135, 135, 135, 0.25), -8px 7px 10px 0px rgba(135, 135, 135, 0.21), -17px 15px 14px 0px rgba(135, 135, 135, 0.13), -31px 27px 16px 0px rgba(135, 135, 135, 0.04), -48px 42px 18px 0px rgba(135, 135, 135, 0.00);
		color: var(--white-color);
		text-decoration: none;
	}

	&_bordered {
		.br(10px);

		background: var(--white-color);
		border: 1px solid var(--black-color);
		box-shadow: none;
		color: var(--black-color);

		&:hover {
			background: var(--black-color);
			border: 1px solid var(--black-color);
			box-shadow: none;
			color: var(--white-color);
		}
	}
	&_primary {
		background: var(--primary-color);
		border-color: var(--primary-color);
		color: var(--grey-color-2);

		&:hover {
			background: var(--primary-color);
			border-color: var(--primary-color);
			color: var(--grey-color-2);
		}
	}
	&_br10 {
		.br(10px);
	}
}

/* select */
select {
	box-sizing: border-box;
	display: block;
	width: 100%;
}
// custom select
.custom-select {
	font-family: var(--font-title);
	position: relative;
	
	select {
		display: none;
	}
	.select-selected {
		.br;

		background-color: var(--black-color);
		border: none;
		color: var(--white-color);
		cursor: pointer;
		font-family: var(--font-title);
		font-size: 16px;
		font-weight: 700;
		line-height: 118%;
		padding: 15px 40px 14px 20px;
		position: relative;

		&:after {
			.br(50%);
			.size(28px);
			.trs(transform);

			background: var(--white-color) url("Images/toggle-arrow-bot-small.svg") top 58% center no-repeat;
			content: "";
			display: block;
			position: absolute;
			right: 10px;
			top: calc(50% - 14px);
			transform: rotate(0);
		}

		&.select-arrow-active {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			&:after {
				transform: rotate(180deg);
			}
		}
	}

	.select-items {
		.br(0, 0, 20px, 20px);

		background-color: var(--black-color);
		color: var(--white-color);
		font-family: var(--font-title);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		left: 0;
		line-height: 1.2;
		padding: 10px 20px 20px;
		position: absolute;
		right: 0;
		top: 100%;
		z-index: 99;
		
		.select-scroll {
			max-height: 128px;
			overflow-x: auto;

			scrollbar-color: var(--white-color) rgba(0, 0, 0, 0);
			scrollbar-width: thin;

			&::-webkit-scrollbar {
				width: 2px;
			}
			
			&::-webkit-scrollbar-track {
				background: rgba(0, 0, 0, 0);
			}
			
			&::-webkit-scrollbar-thumb {
				background-color: var(--white-color);
			}
		}
		&-enternal {

			.select-item {
				background: transparent;
				color: var(--white-color);
				cursor: pointer;
				line-height: 1.3;
				overflow: hidden;
				margin: 0 0 8px;
				text-overflow: ellipsis;
				white-space: nowrap;

				&:last-child {
					margin: 0;
				}
				&:hover {
					color: var(--primary-color);
				}

				&::before {
					.br();
					.size(14px);
					.trs;

					background: transparent;
					border: 1px solid var(--black-color);
					box-sizing: border-box;
					content: '';
					display: inline-block;
					outline: 1px solid currentColor;
					margin: 1px 9px 1px 1px;
					vertical-align: middle;
				}

				&.same-as-selected {
					&::before {
						background: var(--white-color);
					}
				}
			}
		}

		&.select-hide {
			display: none;
		}
	}
}

