/* MIXINS */
/* border radius */
.br(@br: 20px) {
	border-radius: @br;
}
.br(@br1, @br2) {
	border-radius: @br1 @br2;
}
.br(@br1, @br2, @br3) {
	border-radius: @br1 @br2 @br3;
}
.br(@br1, @br2, @br3, @br4) {
	border-radius: @br1 @br2 @br3 @br4;
}
/* size */
.size(@x) {
	height: @x;
	width: @x;
}
.size(@width, @height) {
	height: @height;
	width: @width;
}
/* letter spacing */
.ls-px(@value, @font-size: var(--font-size, 16px)) {
	letter-spacing: @value * @font-size / 1000;
}
.ls(@value) {
	letter-spacing: @value * 1em / 1000;
}

/* transition */
.trs(@setting: all, @time: var(--transition-speed)) {
	transition: @setting @time;
}

/* mediaqueries */
@xxs: ~"(max-width: 359.98px)";
@xxs-up: ~"(min-width: 360px)";
@xs: ~"(max-width: 479.98px)";
@xs-up: ~"(min-width: 480px)";
@sm: ~"(max-width: 575.98px)";
@sm-up: ~"(min-width: 576px)";
@md: ~"(max-width: 767.98px)";
@md-up: ~"(min-width: 768px)";
@lg: ~"(max-width: 991.98px)";
@lg-up: ~"(min-width: 992px)";
@xl: ~"(max-width: 1199.98px)";
@xl-up: ~"(min-width: 1200px)";
@xxl: ~"(max-width: 1399.98px)";
@xxl-up: ~"(min-width: 1400px)";
@xxxl: ~"(max-width: 1899.98px)";
@xxxl-up: ~"(min-width: 1900px)";