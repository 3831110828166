@import "Styles/_mixins";

.filter {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &__item {
        margin-bottom: var(--gutter-x);
        margin-right: var(--gutter-x);
        width: 350px;

        @media @md {
            margin-right: 0;
            width: 100%;
        }
    }
}