.footer {
    background: var(--black-color);
    box-shadow: 0px -4px 4px 0px rgba(157, 157, 157, 0.20);
    color: var(--white-color);

    &__top {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 28px 0;

        @media @xl {
            justify-content: flex-start;
            padding: 20px 0;
        }
        @media @md {
            padding: 16px 0;
        }
    }
    &__bot {
        align-items: center;
        border-top: 1px solid currentColor;
        display: flex;
        justify-content: space-between;
        padding: 18px 0;
        width: 100%;

        @media @xl {
            padding: 16px 0;
        }
        @media @md {
            flex-direction: column;
            padding: 14px 0 16px;
        }
    }
    &__menu {
        display: block;
        box-sizing: border-box;
        padding-left: var(--gutter-x);
        width: 60%;

        @media @xl {
            display: none;
        }
    }
    &__privacy {
        align-items: baseline;
        display: flex;
        flex-wrap: wrap;
        font-size: 0.875rem;

        @media @xl {
            font-size: 0.625rem;
            justify-content: space-between;
            width: 100%;
        }
        @media @md {
            align-items: center;
            flex-direction: column;
            margin-top: 12px;
            order: 2;
        }

        &-item {
            display: block;
            margin-right: 40px;

            @media @md {
                margin: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                color: inherit;
                text-decoration: none;

                &:hover {
                    color: var(--primary-color);
                    text-decoration: none;
                }
            }
        }
    }
    &__logo {
        display: block;
        max-width: 100%;
        width: 90px;

        @media @xl {
            margin-right: 10px;
            width: 40px;
        }
    }
    &__contacts {
        display: block;
        font-size: 1.25rem;
        line-height: 1.1875;

        @media @xl {
            font-size: 0.75rem;
            margin-right: 5%;
        }


        &-item {
            margin: 0 0 10px;

            @media @xl {
                margin-bottom: 5px;
            }

            &:last-child {
                margin: 0;
            }

            a {
                color: inherit;
                text-decoration: none;
                white-space: nowrap;

                &:hover {
                    color: var(--primary-color);
                    text-decoration: none;
                }
            }
        }
    }
}

.logo {
    display: block;
    width: 100%;

    &__img {
        display: block;
        object-fit: contain;
        object-position: center;
        width: 100%;
    }
}