/* HEADER */
.header-wrapper {
    margin: 0 0 20px;

    @media @sm {
        margin-bottom: 10px;
    }
}
.header {
    .container {
        position: relative;
    }

    &__side {
        align-items: center;
        color: var(--white-color);
        display: flex;
        justify-content: flex-start;
        margin-right: 18px;
        padding: 20px 20px 20px 0;
        position: relative;

        @media @xl {
            padding: 10px 10px 10px 0;
        }

        &::before {
            .br(0, 0, 10px, 0);
            .size(50vw, 100%);

            background: var(--black-color);
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;

            @media @lg {
                width: 100vw;
            }
        }
    }
    &__menu {
        @media @xl {
            display: none;
        }
    }
    &__mobile-menu {
        display: none;
        
        @media @xl {
            align-items: center;
            display: flex;
            justify-content: flex-end;
        }
    }
    &__logo {
        .br(10px);

        background: var(--white-color);
        backdrop-filter: blur(2px);
        box-sizing: border-box;
        padding: 10px 12px;
        margin-right: 18px;
        max-height: 100px;
        min-width: 114px;
        width: 114px;

        @media @xl {
            max-height: 57px;
            min-width: 65px;
            padding: 5px 6px;
            width: 65px;
        }
        @media @sm {
            max-height: 38px;
            min-width: 44px;
            padding: 3.5px 4.5px;
            width: 44px;
        }

        .logo {
            .size(100%);

            display: block;
        }
    }
    &__slogan {
        color: #FFF;
        font-family: var(--font-title);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.1875;
        text-wrap: balance;

        @media @xl {
            font-size: 20px;
        }
        @media @lg {
            font-size: 16px;
        }
        @media @xs {
            font-size: 14px;
        }
    }
}

.mobile-menu-trigger {
    .size(32px);

    background: transparent url("../../images/mobile-menu.svg") center center no-repeat;
    display: block;
    position: relative;
    z-index: 112;

    &._openned {
        background-image: url("../../images/mobile-cross.svg");
    }
}
.mobile-menu-dropdown {
    background: var(--white-color);
    box-shadow: 0px 4px 4px 0px rgba(206, 206, 206, 0.25);
    box-sizing: border-box;
    display: none;
    overflow-x: auto;
    padding: 25px 20px 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 400px;
    z-index: 111;
    
    @media @sm {
        margin: 0 0 40px;
        top: 100%;
        width: 100%;
    }

    &__enternal {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }
    &__title {
        font-family: var(--font-title);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        margin: 0 0 1.5em;
    }
    &__privacy {
        color: #868686;
        font-family: var(--font-title);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin: 40px 0 0;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: var(--black-color);
                text-decoration: none;
            }
        }
    }
    &__menu {
        border-bottom: 1px solid var(--line-color);
        color: #000;
        display: block;
        font-family: var(--font-title);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 16px;
        padding: 0 0 16px;

        &-item {
            margin: 0 0 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
        &-link {
            color: inherit;
            position: relative;
            text-decoration: none;

            &:hover,
            &._current {
                color: var(--black-color);
                text-decoration: none;

                &:after {
                    width: 14px;
                }
            }

            &:after {
                .size(0, 2px);
                .trs;

                background: #000;
                bottom: -2px;
                content: '';
                left: 0;
                position: absolute;
                z-index: 1;
            }
        }
    }
    &__submenu {
        color: var(--black-color);
        display: block;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin: 0 0 20px;
        
        &-item {
            margin: 0 0 16px;

            &:last-child {
                margin: 0;
            }
        }
        &-link {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}