@import "Styles/_mixins";

.benefits-list {
    display: grid;
    grid-template: '1fr 1fr 1fr';
    gap: 30px;
    margin: 60px 0;

    @media @xl {
        grid-template: '1fr 1fr';
        margin: 40px 0;
    }
    @media @md {
        grid-template: '1fr';
    }

    &__item {
        .br;

        align-items: center;
        background: var(--white-color);
        box-shadow: 0px 0px 0px 0px rgba(135, 135, 135, 0.25), -2px 2px 6px 0px rgba(135, 135, 135, 0.25), -8px 7px 10px 0px rgba(135, 135, 135, 0.21);
        display: flex;
        justify-content: flex-start;
        padding: 12px;
    }
    &__img-wrap {
        .br;
        .size(78px, 66px);

        align-items: center;
        background: var(--black-color);
        color: var(--white-color);
        display: flex;
        justify-content: center;
        margin-right: 32px;
        min-width: 78px;
    }
    &__img {
        .size(40px);

        display: block;
        object-fit: contain;
        object-position: center;
    }
    &__cont {
        flex: 1;
    }
    &__title {
        font-family: var(--font-title);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        margin: 0 0 2px;
        text-transform: uppercase;

        .icon {
            .size(22px);
            
            color: var(--primary-color);
            display: inline-block;
            margin-right: 12px;
            vertical-align: baseline;

            &.icon-2 {
                color: #bebebe;
            }
            &.icon-3 {
                color: #AB7A49;
            }
        }
    }
    &__desc {
        font-size: 14px;
    }
}