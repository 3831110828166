@import "Styles/_mixins";

.socials {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    &__item {
        margin: 0 12px 0 0;

        @media @lg {
            margin-right: 8px;
        }
        @media @xs {
            margin-right: 5px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
    &__link {
        .br(10px);
        .size(40px);
        .trs;

        align-items: center;
        background: transparent;
        color: inherit;
        display: flex;
        justify-content: center;
        text-decoration: none;

        @media @lg {
            .size(36px)
        }

        &:hover {
            color: var(--primary-color);
            text-decoration: none;
        }

        &_decor {
            @media @xl-up {
                background: var(--grey-color-1);
            }
        }
    }
    .icon {
        .size(24px);

        display: block;
    }
}