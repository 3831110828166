/* VARIABLES */
:root {
    --body-bg: var(--white-color);
    --black-color: #3E3E3E;
    --font-default: 'Open Sans', sans-serif;
    --font-title: 'Roboto', sans-serif;
    --font-size: 1.25rem;
    --grey-color: #D9D9D9;
    --grey-color-1: #949494;
    --grey-color-2: #4a4a4a;
    --gutter-x: 30px;
    --light-grey: #626262;
    --line-height: 1.35;
    --line-color: #EAEAEA;
    --primary-color: #FFDD4C;
    --secondary-color: #CCA500;
    --text-color: var(--black-color);
    --transition-speed: 0.3s;
    --white-color: #ffffff;
}
