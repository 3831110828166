@import "Styles/_mixins";

.implants-list {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 30px 30px;

    &__item {
        display: block;
        width: calc((100% - 2 * 30px) / 3);

        @media @xl {
            width: calc((100% - 30px) / 2);
        }
        @media @md {
            width: 100%;
        }

        &_full {
            width: 100%;
        }
    }
}

.implants-item {
    
    &__cont {
        .br(20px);

        background: var(--white-color);
        box-shadow: 0px 0px 0px 0px rgba(135, 135, 135, 0.25), -2px 2px 6px 0px rgba(135, 135, 135, 0.25), -8px 7px 10px 0px rgba(135, 135, 135, 0.21), -17px 15px 14px 0px rgba(135, 135, 135, 0.13), -31px 27px 16px 0px rgba(135, 135, 135, 0.04), -48px 42px 18px 0px rgba(135, 135, 135, 0.00);
        overflow: hidden;
        padding: 30px 30px 20px;
    }
    &__name {
        font-family: var(--font-title);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        margin: 0 0 10px;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: var(--primary-color);
                text-decoration: none;
            }
        }
    }
    &__img-wrap {
        .br(15px);

        background: var(--white-color);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
        box-sizing: border-box;
        margin: 0 0 18px;
        overflow: hidden;
        padding-top: 58%;
        position: relative;

        &::after {
            .br(inherit);
            .size(100%);

            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            top: 0;
            z-index: 1;
        }
    }
    &__img {
        .size(100%);

        display: block;
        left: 0;
        object-fit: contain;
        object-position: center;
        position: absolute;
        top: 0;
        z-index: 0;
    }
    &__flag {
        .br(10px);

        display: block;
        left: 18px;
        max-height: 29px;
        max-width: 58px;
        object-fit: contain;
        object-position: left top;
        position: absolute;
        top: 18px;
        z-index: 2;
    }
    &__label {
        .br(10px);

        align-items: center;
        background: var(--black-color);
        color: var(--white-color);
        display: flex;
        font-size: 12px;
        font-weight: 500;
        justify-content: center;
        padding: 7px 8px 6px;
        position: absolute;
        right: 18px;
        top: 17px;

        .icon {
            .size(15px);

            display: block;
            margin-right: 5px;
        }
    }
    &__desc {
        display: -webkit-box;
        font-size: 16px;
        font-weight: 400;
        height: calc(4em * var(--line-height));
        overflow: hidden;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
    &__btn-wrap {
        margin-top: 10px;
    }
    &__btn {
        .br(10px);

    }
}