@import "Styles/_mixins";

.system-sidebar {
    &__img-wrap {
        .br;

        display: block;
        overflow: hidden;
        padding-top: 58%;
        position: relative;

        &::after {
            .br(inherit);
            .size(100%);

            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            top: 0;
            z-index: 2;
        }
    }
    &__img {
        .size(100%);

        display: block;
        left: 0;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
    }
    &__btn-wrap {
        margin: 20px 0 0;
    }
    &__btn {
        font-size: 20px;
    }
}