@import "Styles/_mixins";

.footer-developer {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    &__icon {
        .size(34px);

        color: inherit;
        display: block;
        margin-right: 4px;

        .icon {
            .size(100%);

            display: block;
        }
    }
    &__cont {
        font-family: 'San Francisco Pro Display';
        font-size: 14px;
        line-height: 1.21;

        &-line {
            display: block;
            margin: 0 0 .01em;
            white-space: nowrap;

            &:last-child {
                margin: 0;
                position: relative;

                &:after {
                    .size(21px, 1px);

                    background: currentColor;
                    bottom: 0;
                    content: '';
                    display: block;
                    left: 4em;
                    position: absolute;
                }
            }

            a {
                color: inherit;
                font-size: 1rem;
                line-height: 1.2;
                text-decoration: underline;
                
                &:hover {
                    color: var(--primary-color);
                    text-decoration: underline;
                }
            }
        }
    }
}