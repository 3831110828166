@import "Styles/_mixins";

.brands-slider {
    display: block;
    overflow: hidden;

     &_horizontal {
        touch-action: pan-y;
    }

    &__wrapper {
        align-items: stretch;
        display: flex;
        width: 100%;
    }
    &__item {
        align-items: stretch;
        display: flex;
        flex-shrink: 0;
        justify-content: stretch;
        max-width: 100%;
        width: 214px;
    }
    &__link {
        display: block;
        width: 100%;
    }
    &__img {
        .size(100%);
        
        display: block;
        object-fit: contain;
        object-position: center;
    }
}