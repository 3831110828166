@import "Styles/_mixins";

.header-menu {
    align-items: start;
    display: block;
    flex: 1;

    &__line {
        align-items: center;
        border-bottom: 1px solid currentColor;
        display: flex;
        font-size: 0.875rem;
        justify-content: space-between;
        padding: 0.62rem 0;

        &:first-child {
            font-size: 1rem;
        }
        &:last-child {
            border: none;
        }

    }
    &__item {
        display: block;
    }
    &__link {
        color: currentColor;
        display: inline-block;
        position: relative;
        text-decoration: none;
        
        &:hover {
            color: currentColor;
            text-decoration: none;

            &::after {
                height: 1em;
            }
        }

        &::after {
            .size(2px, 0);
            .trs;

            background: var(--primary-color);
            content: "";
            display: block;
            left: -6px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &._current {
            color: var(--secondary-color);
        }
    }
}