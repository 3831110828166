@import "Styles/_mixins";

.page-slider {
    .br;

    background: var(--white-color);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), -3px 1px 6px 0px rgba(0, 0, 0, 0.10), -11px 4px 11px 0px rgba(0, 0, 0, 0.09), -24px 9px 15px 0px rgba(0, 0, 0, 0.05), -42px 16px 18px 0px rgba(0, 0, 0, 0.01), -66px 25px 20px 0px rgba(0, 0, 0, 0.00);
    display: block;
    overflow: hidden;
    padding: 0;
    position: relative;

     &_horizontal {
        touch-action: pan-y;
    }

    &__wrapper {
        align-items: stretch;
        display: flex;
        width: 100%;
    }
    &__item {
        align-items: stretch;
        display: flex;
        flex-shrink: 0;
        justify-content: stretch;
        max-width: 100%;
        width: 100%;
    }
    &__link {
        display: block;
        width: 100%;
    }
    &__img {
        display: block;
        object-fit: contain;
        object-position: center;
        width: 100%;
    }
    &__pagination {
        align-items: center;
        bottom: 10px;
        display: flex;
        justify-content: center;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);

        &-item {
            .br(50%);
            .size(12px);
            .trs;

            border: 1px solid #ADADAD;
            box-sizing: border-box;
            display: block;
            margin-right: 8px;

            &:hover {
                border-color: var(--white-color);
            }

            &:last-child {
                margin-right: 0;
            }

            &_clickable {
                cursor: pointer;
            }
            &_active {
                background-color: var(--white-color);
                border-color: var(--white-color);
            }
        }
    }
    &__action {
        .br(3px);
        .size(40px);
        .trs;

        align-items: center;
        background: #6F6F6F;
        box-shadow: 0px 0px 0px 0px rgba(135, 135, 135, 0.25), -2px 2px 6px 0px rgba(135, 135, 135, 0.25), -8px 7px 10px 0px rgba(135, 135, 135, 0.21), -17px 15px 14px 0px rgba(135, 135, 135, 0.13), -31px 27px 16px 0px rgba(135, 135, 135, 0.04), -48px 42px 18px 0px rgba(135, 135, 135, 0.00);
        color: var(--grey-color-1);
        cursor: pointer;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-decoration: none;

        @media @sm {
            .size(32px);
        }

        &:hover {
            background: var(--white-color);
        }

        &_prev {
            left: 20px;

            @media @sm {
                left: 0;
            }

            .icon {
                transform: rotate(180deg);
            }
        }
        &_next {
            right: 20px;

            @media @sm {
                right: 0;
            }
        }

        .icon {
            .size(10px, 20px);

            display: block;
        }
    }
}